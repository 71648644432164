import * as dat from 'lil-gui'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import firefliesVertexShader from './shaders/fireflies/vertex.glsl'
import firefliesFragmentShader from './shaders/fireflies/fragment.glsl'
import portalVertexShader from './shaders/portal/vertex.glsl'
import portalFragmentShader from './shaders/portal/fragment.glsl'

/**
 * Base
 */
// Debug
const debugObject = {}
const gui = new dat.GUI({
    width: 400
})

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader()

// Draco loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

/**
 * Textures
 */
const bakedTexture = textureLoader.load('baked.jpg');
bakedTexture.flipY = false;
bakedTexture.colorSpace = THREE.SRGBColorSpace;

/**
 * Materials
 */
//Baked
const bakedMaterial = new THREE.MeshBasicMaterial(
    {
        map : bakedTexture
    }
);

const poleLightMaterial = new THREE.MeshBasicMaterial({color: 0xFFFFF5});


debugObject.portalColorStart = '#34adc5'
debugObject.portalColorEnd = '#eeeeff'
gui
    .addColor(debugObject, 'portalColorStart')
    .onChange(() =>
    {
        portalMaterial.uniforms.uColorStart.value.set(debugObject.portalColorStart)
    })
    
gui
    .addColor(debugObject, 'portalColorEnd')
    .onChange(() =>
    {
        portalMaterial.uniforms.uColorEnd.value.set(debugObject.portalColorEnd)
    })


const portalMaterial = new THREE.ShaderMaterial({
    uniforms:
    {
        uTime: { value: 0 },
        uColorStart: { value: new THREE.Color(0x34adc5) },
        uColorEnd: { value: new THREE.Color(0xeeeeff) }
    },
    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader
})


/**
 * Model
 */

gltfLoader.load(
    'portal.glb',
    (gltf) => 
    {
        const bakedMesh = gltf.scene.children.find((child) => child.name === 'baked');
        const poleLight1 = gltf.scene.children.find((child) => child.name === 'poleLight1');
        const poleLight2 = gltf.scene.children.find((child) => child.name === 'poleLight2');
        const portalLight = gltf.scene.children.find((child) => child.name === 'portalLight');
        bakedMesh.material = bakedMaterial;
        poleLight1.material = poleLightMaterial;
        poleLight2.material = poleLightMaterial;
        portalLight.material = portalMaterial;
        scene.add(gltf.scene);
    }
)

/**
 * Fireflies
 */

//Geometry
const fireFliesGeometry = new THREE.BufferGeometry();
const fireFliesCount = 30;
const positionArray = new Float32Array(fireFliesCount * 3)
const scaleArray = new Float32Array(fireFliesCount);

for (let i = 0; i< fireFliesCount ; i++){
    positionArray[i * 3 + 0] = (Math.random() - 0.5) * 4;
    positionArray[i * 3 + 1] = Math.random() * 1.5;
    positionArray[i * 3 + 2] = (Math.random() - 0.5) * 4;
    scaleArray[i] = Math.max(Math.random(), 0.5);
}
fireFliesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray,3));
fireFliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))

const firefliesMaterial = new THREE.ShaderMaterial({
    uniforms:
    {
        uTime: { value: 0 },
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
        uSize: { value: 100 }
    },
    vertexShader : firefliesVertexShader,
    fragmentShader : firefliesFragmentShader,
    blending: THREE.AdditiveBlending,
    depthWrite : false,
    transparent : true
});
gui.add(firefliesMaterial.uniforms.uSize, 'value').min(0).max(500).step(1).name('firefliesSize')

const fireFlies = new THREE.Points(fireFliesGeometry, firefliesMaterial);
scene.add(fireFlies);

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update fireflies
    firefliesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)
})


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 4
camera.position.y = 2
camera.position.z = 4
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Clear color
debugObject.clearColor = '#15222e'
gui.addColor(debugObject, 'clearColor').onChange(() => {renderer.setClearColor(debugObject.clearColor)})
renderer.setClearColor(debugObject.clearColor)

gui.close();

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    // Update materials
    firefliesMaterial.uniforms.uTime.value = elapsedTime
    portalMaterial.uniforms.uTime.value = elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()